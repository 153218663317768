<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <icon-class-bar
          :title="$t('general.experience_marks')"
          :marks="true"
          :comments="true"
          :presence="true"
          :edit_marks="b_EditMarks"
          :new_mark="b_NewMark"
          current="experience-marks"
          @edit-mode="editMode=true"
          @new-mark="dialog = true"
        />
        <v-card-text>
          <div
            class="d-flex justify-space-between"
          >
            <v-select
              v-model="i_CurrentSeminarCenterID"
              :items="a_SeminarCenter"
              item-text="s_Name"
              item-value="i_SeminarCenterID"
              filled
              label="Standort"
              dense
              outlined
              color="success"
              @change="setSeminarCenterID"
              class="mr-2"
              v-if="b_IsTeacherAdmin"
            />
            <v-select
              v-model="o_CurrentStudies.i_StudiesID"
              :items="a_StudiesWithClasses"
              item-text="s_Name"
              item-value="i_StudiesID"
              filled
              :label="$t('study')"
              dense
              outlined
              color="success"
              @change="clearDataTables"
              class="mr-2"
            />
            <v-select
              v-model="o_CurrentClass.i_ClassID"
              :items="a_Class"
              item-text="s_Name"
              item-value="i_ClassID"
              filled
              :label="$t('class')"
              dense
              outlined
              color="success"
              @change="clearDataTables"
              class="mr-2"
            />
            <v-select
              v-model="i_CurrentClassSubjectID"
              :items="a_ClassSubject"
              item-text="s_Name"
              item-value="i_ClassSubjectID"
              filled
              :label="$t('general.subject')"
              dense
              outlined
              color="success"
            />
          </div>
          <div class="d-flex flex-row-reverse mb-6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </div>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="a_Header"
            :items="a_Student"
            class="elevation-1 table-one"
            multi-sort
            dense
            :items-per-page="-1"
            :loading="b_LoadingStudent"
          >
            <template
              v-for="i_TermMarkDateID in nameHeaders"
              v-slot:[`header.${i_TermMarkDateID}`]="{ header }"
            >
              {{ header.s_Name }}<br>
              {{ header.t_Date.substr(0, 10) }}<br>
              {{ header.s_ExamTypeName }}<br>
              {{ $t('grades.factor') }}: {{ header.f_Factor }}
              <v-btn
                icon
                color="primary"
                @click="editTermMarkDate($event, header)"
              >
                <v-icon size="medium" color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.s_Lastname="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="require('@/assets/images/avatars/001-man.svg')"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Lastname }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_Firstname="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Firstname }}
                </p>
              </div>
            </template>
            <template
              v-for="(i_TermMarkDateID, indexMark) in nameHeaders"
              v-slot:[`item.${i_TermMarkDateID}`]="{ item }"
            >
              <p
                v-if="!editMode"
                class="ma-0 font-weight-medium"
                v-bind:key="indexMark"
              >
                {{ (getTermMarkForSlot(i_TermMarkDateID, item) !== null) ? getTermMarkForSlot(i_TermMarkDateID, item) : '-' }}
              </p>
              <v-row
                v-else
                no-gutters
                class="my-1"
                v-bind:key="indexMark"
              >
                <v-col>
                  <v-text-field
                    :value="getTermMarkForSlot(i_TermMarkDateID, item)"
                    :disabled="false"
                    hide-details="auto"
                    outlined
                    dense
                    :error-messages="getErrorMessages(item.i_AddressRoleID, i_TermMarkDateID)"
                    @change="updateTermMark(item, i_TermMarkDateID, $event)"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer>
              <div
                style="position: absolute"
                class="pl-2 pt-2"
              >
                <v-chip
                  class="ma-2"
                  label
                  color="teal"
                  text-color="white"
                >
                  {{ $t('grades.average') }}: {{ getAverage }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <div
            v-if="editMode"
            class="d-flex flex-wrap mt-4"
          >
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="setTermMarks()"
            >
              {{ $t('general.save') }}
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="cancelSaveTermMarks()"
            >
              {{ $t('general.cancel') }}
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card class="">
        <v-card-title>
          <span class="text-h5">{{$t('grades.add_term_mark')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="o_NewTermMarkDate.s_Name"
                  :label="'* '+$t('general.name')"
                  :error-messages="termMarkDateNameErrors"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="o_NewTermMarkDate.s_Factor"
                  :label="'* '+$t('grades.factor')"
                  :error-messages="termMarkDateFactorErrors"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="a_ExamTypeID.length > 0"
              >
                <v-select
                  v-model="o_NewTermMarkDate.i_ExamTypeID"
                  :items="a_ExamTypeID"
                  item-text="s_Name"
                  item-value="i_ExamTypeID"
                  filled
                  :label="$t('Term mark date type')"
                  dense
                  outlined
                  color="success"
                  @change=""
                  class="mr-2"
                  :error-messages="termMarkDateExamTypeIDErrors"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="s_ClassDate"
                      label="Datum"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="s_ClassDate"
                    @input="menuDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <div>
                  <v-checkbox
                    v-model="o_NewTermMarkDate.b_Time"
                    :label="$t('Define times')"
                  ></v-checkbox>
                </div>
                <div class="d-flex" v-if="o_NewTermMarkDate.b_Time">
                  <v-card
                    class="mx-auto mr-2"
                    outlined
                  >
                    <div class="ml-2 mt-1 py-0">{{ $t('Start') }}</div>
                    <div class="d-flex my-0 py-0">
                      <v-select
                        v-model="o_NewTermMarkDate.i_StartHour"
                        :items="a_Hours"
                        class="ml-2 mr-2 my-0 py-0"
                        hide-details
                        dense
                        :error-messages="startHourErrors"
                      />
                      <v-text-field
                        v-model="o_NewTermMarkDate.i_StartMinutes"
                        :error-messages="startMinutesErrors"
                        class="my-0 py-0 mr-2"
                        dense
                      />
                    </div>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    outlined
                  >
                    <div class="ml-2 mt-1 py-0">{{ $t('End') }}</div>
                    <div class="d-flex my-0 py-0">
                      <v-select
                        v-model="o_NewTermMarkDate.i_EndHour"
                        :items="a_EndHours"
                        class="ml-2 mr-2 my-0 py-0"
                        hide-details
                        dense
                        :error-messages="endHourErrors"
                      />
                      <v-text-field
                        v-model="o_NewTermMarkDate.i_EndMinutes"
                        :error-messages="endMinutesErrors"
                        class="my-0 py-0 mr-2"
                        dense
                      />
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger darken-1"
            text
            @click="deleteTermMarkDate"
          >
            {{$t('general.delete')}}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="cancelEditTermMarkDate"
          >
            {{$t('general.cancel')}}
          </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="setTermMarkDate"
          >
            {{$t('general.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get, post, del } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { decimal, between, requiredIf, helpers } from 'vuelidate/lib/validators'
  import { config } from '../../../data/config'

  const fiveStep = (value) => !helpers.req(value) || value % 5 === 0
  const between0and55 = (value) => !helpers.req(value) || (parseInt(value) >= 0 && parseInt(value) <= 55)
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Erfahrungsnoten',
    },
    components: {
      IconClassBar,
    },
    mixins: [validationMixin],
    data () {
      return {
        o_NewTermMarkDate: {
          i_ExamTypeID: null,
          s_Name: null,
          s_Factor: null,
          i_TermMarkDateID: 0,
          b_Time: false,
          i_StartHour: null,
          i_EndHour: null,
          i_StartMinutes: null,
          i_EndMinutes: null,
          i_LessonID: 0
        },
        o_CurrentClass: {
          i_ClassID: null,
          s_Name: null,
        },
        o_CurrentStudies: {
          i_StudiesID: null,
          s_Name: null,
        },
        i_CurrentClassSubjectID: null,
        dialog: false,
        editMode: false,
        search: '',
        selected: [],
        a_Header: [],
        a_ConfigHeader: [
          {
            text: 'Nachname',
            align: 'start',
            sortable: true,
            value: 's_Lastname',
          },
          {
            text: 'Vorname',
            align: 'start',
            sortable: true,
            value: 's_Firstname',
          },
          { text: 'Schnitt', value: 's_SnipText' },
          { text: 'Quartal', value: 'quarter' },
        ],
        a_ClassStudent: [],
        a_Studies: [],
        a_TermMarkDate: [],
        a_TermMarkError: [],
        a_QuarterMark: [],
        f_CurrentResult: null,
        menuDate: false,
        s_ClassDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
        b_EditMarks: false,
        b_NewMark: false,
        b_IsTeacherAdmin: false,
        a_SeminarCenter: [],
        i_CurrentSeminarCenterID: null,
        a_StudiesWithClasses: [],
        b_LoadingStudent: false,
        i_TeacherAddressRoleID: null,
        a_ExamTypeID: [],
        b_EditTermMarkDate: false,
        a_Hours: ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
        b_EnterTime: false
      }
    },
    watch: {
      async i_CurrentSeminarCenterID (val) {
        if (val === null) return
        await this.getTeacherStudies()
        this.getStudiesWithClasses()
      },
      async i_CurrentClassSubjectID (val) {
        this.a_Header = [...this.a_ConfigHeader];
        this.editMode=false
        this.b_EditMarks = false
        this.b_NewMark = false
        if (val === null) return
        this.b_LoadingStudent = true
        await this.getTermMarkDates()
        const La_SubjectTermMarkDate = this.a_TermMarkDate.filter(o_TermMarkDate => {
          return o_TermMarkDate.i_ClassSubjectID === val
        })
        //this.a_Header.splice(2, 0, ...La_SubjectTermMarkDate)
      },
      a_Studies (val) {
        if (val.length == 1) {
          this.o_CurrentStudies.i_StudiesID = this.a_Studies[0].i_StudiesID
          if (this.a_Studies[0].a_Class.length == 1) {
            this.o_CurrentClass.i_ClassID = this.a_Studies[0].a_Class[0].i_ClassID
            if (this.a_Studies[0].a_Class[0].a_ClassSubject.length == 1) {
              this.i_CurrentClassSubjectID = this.a_Studies[0].a_Class[0].a_ClassSubject[0].i_ClassSubjectID
            }
          }
        }
        this.getStudiesWithClasses()
      },
      i_PlanningPeriodID () {
        this.a_ClassStudent = []
        this.a_StudiesWithClasses = []
        this.a_Studies = []
        this.i_CurrentSeminarCenterID = null
        this.i_CurrentClassSubjectID = null
        this.a_Header = [...this.a_ConfigHeader]
        this.o_CurrentStudies = {
          i_StudiesID: null,
          s_Name: null,
        }
        this.o_CurrentClass = {
          i_ClassID: null,
          s_Name: null,
        }
        this.b_LoadingStudent = false
        if (!this.b_IsTeacherAdmin) this.getTeacherStudies()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getRoleNames', 'getSeminarCenter', 'getImpersonation']),
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      nameHeaders () {
        return this.a_TermMarkDate.map(termMarkDate => {
          return termMarkDate.value
        })
      },
      a_Class () {
        const La_ClassWithSubjects = []
        const a_CurrentStudies = this.a_StudiesWithClasses.filter(o_Studies => {
          return o_Studies.i_StudiesID === this.o_CurrentStudies.i_StudiesID
        })
        if(a_CurrentStudies.length > 0) {
          if (this.b_IsTeacherAdmin) {
            const La_ClassCurrentSeminarCenter = a_CurrentStudies[0].a_Class.filter(o_Class => {
              return o_Class.i_SeminarCenterID === this.i_CurrentSeminarCenterID
            })
            La_ClassCurrentSeminarCenter.forEach(o_Class => {
              if (o_Class.a_ClassSubject.length > 0) La_ClassWithSubjects.push(o_Class)
            })
          }
          else {
            a_CurrentStudies[0].a_Class.forEach(o_Class => {
              if (o_Class.a_ClassSubject.length > 0) La_ClassWithSubjects.push(o_Class)
            })
          }
          return La_ClassWithSubjects.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
        }
        else return [];
      },
      a_ClassSubject () {
        const a_CurrentClass = this.a_Class.filter(o_Class => {
          return o_Class.i_ClassID === this.o_CurrentClass.i_ClassID
        })
        if (a_CurrentClass.length > 0) return a_CurrentClass[0].a_ClassSubject.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
        else return []
      },
      a_Student () {
        if (
          this.a_ClassStudent.length < 1 ||
          this.i_CurrentClassSubjectID === null
        ) return this.a_ClassStudent;
        const La_Student = this.a_ClassStudent.map(o_Student => {
          return {
            ...o_Student, 
            a_TermMarks: o_Student.a_TermMarks.filter(
              o_TermMark => {
                return o_TermMark.i_ClassSubjectID === this.i_CurrentClassSubjectID || o_TermMark.i_SentClassSubjectID === this.i_CurrentClassSubjectID
              }
            )
          }
        })
        return La_Student.sort((a, b) => a.s_Lastname > b.s_Lastname ? 1 : -1)
      },
      getAverage () {
        let f_Average = 0;
        let f_SpinSum = 0;
        let i_CountStudents = 0;
        this.a_Student.forEach(o_Student => {
          if(o_Student.snip) {
            f_SpinSum += parseFloat(o_Student.snip);
            i_CountStudents++;
          }
        });
        if(i_CountStudents > 0) f_Average = f_SpinSum / i_CountStudents;
        return (f_Average).toFixed(2);
      },
      termMarkDateNameErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.s_Name.$dirty) return errors
        !this.$v.o_NewTermMarkDate.s_Name.required &&
          errors.push(this.$t("general.required_field"))
        return errors
      },
      termMarkDateFactorErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.s_Factor.$dirty) return errors
        !this.$v.o_NewTermMarkDate.s_Factor.required &&
          errors.push(this.$t("general.required_field"))
        return errors
      },
      termMarkDateExamTypeIDErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.i_ExamTypeID.$dirty) return errors
        !this.$v.o_NewTermMarkDate.i_ExamTypeID.required &&
          errors.push(this.$t("general.required_field"))
        return errors
      },
      startHourErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.i_StartHour.$dirty) return errors
        !this.$v.o_NewTermMarkDate.i_StartHour.required &&
          errors.push(this.$t("general.required_field"))
        !this.$v.o_NewTermMarkDate.i_StartHour.between &&
          errors.push(this.$t("Must be between 6 and 23"))
        return errors
      },
      endHourErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.i_EndHour.$dirty) return errors
        !this.$v.o_NewTermMarkDate.i_EndHour.required &&
          errors.push(this.$t("general.required_field"))
        !this.$v.o_NewTermMarkDate.i_EndHour.between &&
          errors.push(this.$t("Must be between 6 and 23"))
        return errors
      },
      startMinutesErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.i_StartMinutes.$dirty) return errors
        !this.$v.o_NewTermMarkDate.i_StartMinutes.required &&
          errors.push(this.$t("general.required_field"))
        !this.$v.o_NewTermMarkDate.i_StartMinutes.fiveStep &&
          errors.push(this.$t("Only in 5 minutes steps"))
        !this.$v.o_NewTermMarkDate.i_StartMinutes.between &&
          errors.push(this.$t("Must be between 0 and 60"))
        return errors
      },
      endMinutesErrors() {
        const errors = []
        if (!this.$v.o_NewTermMarkDate.i_EndMinutes.$dirty) return errors
        !this.$v.o_NewTermMarkDate.i_EndMinutes.required &&
          errors.push(this.$t("general.required_field"))
        !this.$v.o_NewTermMarkDate.i_EndMinutes.fiveStep &&
          errors.push(this.$t("Only in 5 minutes steps"))
        !this.$v.o_NewTermMarkDate.i_EndMinutes.between &&
          errors.push(this.$t("Must be between 0 and 60"))
        !this.$v.t_EndDateTMD.minValue &&
          errors.push(this.$t("Must be greater than the start date"))
        return errors
      },
      a_EndHours () {
        return this.a_Hours.filter(h => parseInt(h) >= parseInt(this.o_NewTermMarkDate.i_StartHour))
      },
      t_StartDateTMD () {
        if (!this.o_NewTermMarkDate.i_StartHour || !this.o_NewTermMarkDate.i_StartMinutes) return null
        try {
          let Lt_StartDateTime = new Date(this.s_ClassDate)
          Lt_StartDateTime.setHours(parseInt(this.o_NewTermMarkDate.i_StartHour))
          Lt_StartDateTime.setMinutes(parseInt(this.o_NewTermMarkDate.i_StartMinutes))
          return Lt_StartDateTime
        } catch {
          return null
        }
      },
      t_EndDateTMD () {
        if (!this.o_NewTermMarkDate.i_EndHour || !this.o_NewTermMarkDate.i_EndMinutes) return null
        try {
          let Lt_EndDateTime = new Date(this.s_ClassDate)
          Lt_EndDateTime.setHours(parseInt(this.o_NewTermMarkDate.i_EndHour))
          Lt_EndDateTime.setMinutes(parseInt(this.o_NewTermMarkDate.i_EndMinutes))
          return Lt_EndDateTime
        } catch {
          return null
        }
      }
    },
    methods: {
      getStudiesWithClasses () {
        let La_StudiesSeminarCenter 
        let La_StudiesFilteredClass
        if (this.b_IsTeacherAdmin) {
          La_StudiesSeminarCenter = this.a_Studies.filter(o_Study => {
            return o_Study.a_Class.some(o_Class => {
              return o_Class.i_SeminarCenterID === this.i_CurrentSeminarCenterID
            }) 
          })
          La_StudiesFilteredClass = La_StudiesSeminarCenter.map(o_Study => ({
            ...o_Study,
            a_Class: o_Study.a_Class.filter(o_Class => {
              return o_Class.i_SeminarCenterID === this.i_CurrentSeminarCenterID
            })
          }))
        }
        else La_StudiesFilteredClass = this.a_Studies
        this.a_StudiesWithClasses = La_StudiesFilteredClass.filter(o_Study => {
          return o_Study.a_Class.some(o_Class => {
            return o_Class.a_ClassSubject.length > 0
          }) 
        })
      },
      addHeaderTermMark (Ao_TermMark) {
        this.a_Header.splice(-2, 0, {
          s_Name: Ao_TermMark.s_Name,
          value: Ao_TermMark.i_TermMarkDateID,
          f_Factor: Ao_TermMark.s_Factor + '%',
          t_Date: this.s_ClassDate,
          i_TermMarkDateID: Ao_TermMark.i_TermMarkDateID,
          i_ExamTypeID: Ao_TermMark.i_ExamTypeID,
          s_ExamTypeName: Ao_TermMark.s_ExamTypeName,
          i_LessonID: Ao_TermMark.i_LessonID,
          i_StartHour: Ao_TermMark.i_StartHour,
          i_EndHour: Ao_TermMark.i_EndHour,
          i_StartMinutes: Ao_TermMark.i_StartMinutes,
          i_EndMinutes: Ao_TermMark.i_EndMinutes,
        })
        this.nameHeaders.push(Ao_TermMark.i_TermMarkDateID)
      },
      setTermMarkDate () {
        if (!this.validateTermMarkDate()) return
        this.$v.$reset()
        this.dialog = false
        this.b_EditTermMarkDate = false
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark-date`, {
          i_ClassSubjectID: parseInt(this.i_CurrentClassSubjectID),
          i_AddressRoleID: this.i_TeacherAddressRoleID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ExamTypeID: parseInt(this.o_NewTermMarkDate.i_ExamTypeID),
          s_Name: this.o_NewTermMarkDate.s_Name,
          f_Factor: parseFloat(this.o_NewTermMarkDate.s_Factor),
          s_Date: this.s_ClassDate,
          i_TermMarkDateID: parseInt(this.o_NewTermMarkDate.i_TermMarkDateID),
          i_LessonID: parseInt(this.o_NewTermMarkDate.i_LessonID),
          b_Time: this.o_NewTermMarkDate.b_Time,
          o_Time: {
            i_StartHour: this.o_NewTermMarkDate.i_StartHour,
            i_EndHour: this.o_NewTermMarkDate.i_EndHour,
            i_StartMinutes: parseInt(this.o_NewTermMarkDate.i_StartMinutes) < 10 && 
              this.o_NewTermMarkDate.i_StartMinutes.length < 2
              ? '0' + this.o_NewTermMarkDate.i_StartMinutes : this.o_NewTermMarkDate.i_StartMinutes,
            i_EndMinutes: parseInt(this.o_NewTermMarkDate.i_EndMinutes) < 10 && 
              this.o_NewTermMarkDate.i_EndMinutes.length < 2
              ? '0' + this.o_NewTermMarkDate.i_EndMinutes : this.o_NewTermMarkDate.i_EndMinutes
          }
        }).then(
          response => {
            const Li_IndexExamType = this.a_ExamTypeID.findIndex(et => et.i_ExamTypeID === response.i_ExamTypeID)
            if (parseInt(this.o_NewTermMarkDate.i_TermMarkDateID) === 0) {
              this.addHeaderTermMark({
                s_Name: this.o_NewTermMarkDate.s_Name,
                value: response.i_TermMarkDateID,
                s_Factor: this.o_NewTermMarkDate.s_Factor,
                i_TermMarkDateID: response.i_TermMarkDateID,
                i_ExamTypeID: response.i_ExamTypeID,
                s_ExamTypeName: Li_IndexExamType != -1 ? this.a_ExamTypeID[Li_IndexExamType].s_Name : '',
                i_LessonID: response.i_LessonID,
                i_StartHour: response.o_Time.i_StartHour,
                i_EndHour: response.o_Time.i_EndHour,
                i_StartMinutes: response.o_Time.i_StartMinutes,
                i_EndMinutes: response.o_Time.i_EndMinutes
              })
              this.a_TermMarkDate.push({
                b_VirtualFlag: false,
                f_Factor: parseFloat(this.o_NewTermMarkDate.s_Factor),
                i_ClassGroupID: response.i_ClassGroupID,
                i_ClassID: this.o_CurrentClass.i_ClassID,
                i_ClassSubjectID: this.i_CurrentClassSubjectID,
                i_ExamTypeID: response.i_ExamTypeID,
                s_ExamTypeName: Li_IndexExamType != -1 ? this.a_ExamTypeID[Li_IndexExamType].s_Name : '',
                i_TermMarkDateID: response.i_TermMarkDateID,
                s_Name: this.o_NewTermMarkDate.s_Name,
                t_Date: this.s_ClassDate,
                type: 'mark',
                value: response.i_TermMarkDateID,
                i_LessonID: response.i_LessonID
              })
            }
            else {
              const La_HeaderToUpdate = this.a_Header.filter(header => header.i_TermMarkDateID === response.i_TermMarkDateID)
              if (La_HeaderToUpdate.length > 0) {
                La_HeaderToUpdate[0].s_Name = this.o_NewTermMarkDate.s_Name
                La_HeaderToUpdate[0].f_Factor = parseFloat(this.o_NewTermMarkDate.s_Factor)
                La_HeaderToUpdate[0].t_Date = this.s_ClassDate
                La_HeaderToUpdate[0].i_LessonID = response.i_LessonID
                La_HeaderToUpdate[0].i_StartHour = response.o_Time.i_StartHour
                La_HeaderToUpdate[0].i_EndHour = response.o_Time.i_EndHour
                La_HeaderToUpdate[0].i_StartMinutes = response.o_Time.i_StartMinutes
                La_HeaderToUpdate[0].i_EndMinutes = response.o_Time.i_EndMinutes
                La_HeaderToUpdate[0].s_ExamTypeName = this.a_ExamTypeID[Li_IndexExamType].s_Name
              }
              const Li_Tmd = this.a_TermMarkDate.findIndex(tmd => tmd.i_TermMarkDateID === response.i_TermMarkDateID)
              this.a_TermMarkDate[Li_Tmd].i_ExamTypeID = response.i_ExamTypeID
              this.a_TermMarkDate[Li_Tmd].s_ExamTypeName = this.a_ExamTypeID[Li_IndexExamType].s_Name
            }
            this.o_NewTermMarkDate.s_Name = null
            this.o_NewTermMarkDate.s_Factor = null
            this.a_ExamTypeID.length < 1 ? this.o_NewTermMarkDate.i_ExamTypeID = 1 : null
            this.o_NewTermMarkDate.i_StartHour = null
            this.o_NewTermMarkDate.i_StartMinutes = null
            this.o_NewTermMarkDate.i_EndHour = null
            this.o_NewTermMarkDate.i_EndMinutes = null
            this.o_NewTermMarkDate.i_TermMarkDateID = 0
            this.o_NewTermMarkDate.i_LessonID = 0
            this.s_ClassDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
          }
        ).catch(
          error => {
          }
        )
      },
      deleteTermMarkDate () {
        if(!confirm(this.$t('Are you sure you want to delete this item?'))) return
        this.dialog = false
        this.b_EditTermMarkDate = false
        const Li_HeaderIndex = this.a_Header.findIndex(header => header.i_TermMarkDateID === parseInt(this.o_NewTermMarkDate.i_TermMarkDateID))
        const Li_TermMarkDateIndex = this.a_TermMarkDate.findIndex(termMarkDate => termMarkDate.i_TermMarkDateID === parseInt(this.o_NewTermMarkDate.i_TermMarkDateID))
        if (Li_HeaderIndex === -1 || Li_TermMarkDateIndex === -1 || parseInt(this.o_NewTermMarkDate.i_TermMarkDateID) === 0) return
        del(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark-date`, {
          i_ClassSubjectID: parseInt(this.i_CurrentClassSubjectID),
          i_AddressRoleID: this.i_TeacherAddressRoleID,
          i_TermMarkDateID: parseInt(this.o_NewTermMarkDate.i_TermMarkDateID),
        }).then(
          response => {
            this.a_Header.splice(Li_HeaderIndex, 1)
            this.a_TermMarkDate.splice(Li_TermMarkDateIndex, 1)
            const La_ClassStudent = this.a_ClassStudent.map(o_Student => {
              return {
                ...o_Student, 
                a_TermMarks: o_Student.a_TermMarks.filter(
                  o_TermMark => {
                    return o_TermMark.i_TermMarkDateID !== this.o_NewTermMarkDate.i_TermMarkDateID
                  }
                )
              }
            })
            this.a_ClassStudent = [...La_ClassStudent]
            this.o_NewTermMarkDate.s_Name = null
            this.o_NewTermMarkDate.s_Factor = null
            this.a_ExamTypeID.length < 1 ? this.o_NewTermMarkDate.i_ExamTypeID = 1 : null
            this.o_NewTermMarkDate.i_StartHour = null
            this.o_NewTermMarkDate.i_StartMinutes = null
            this.o_NewTermMarkDate.i_EndHour = null
            this.o_NewTermMarkDate.i_EndMinutes = null
            this.o_NewTermMarkDate.i_TermMarkDateID = 0
            this.o_NewTermMarkDate.i_LessonID = 0
            this.o_NewTermMarkDate.b_Time = false
            this.s_ClassDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
          }
        ).catch(
          error => {
          }
        )
      },
      getTermMarkDates () {
        this.a_ClassStudent = []
        if (this.i_CurrentClassSubjectID === null) {
          this.a_TermMarkDate = []
          return
        }
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark-date`, {
          i_ClassID: this.o_CurrentClass.i_ClassID,
          i_AddressRoleID: this.i_TeacherAddressRoleID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ClassSubjectID: this.i_CurrentClassSubjectID,
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_TermMarkDate = response.TermMarkDates.map(tmd => {
              return {
                ...tmd,
                i_StartHour: tmd.i_StartTime > 0 
                  ? tmd.i_StartTime.toString().slice(0, tmd.i_StartTime.toString().length - 2).padStart(2, '0')
                  : null,
                i_StartMinutes: tmd.i_StartTime > 0 ? tmd.i_StartTime.toString().slice(-2) : null,
                i_EndHour: tmd.i_EndTime > 0 
                  ? tmd.i_EndTime.toString().slice(0, tmd.i_EndTime.toString().length - 2).padStart(2, '0') 
                  : null,
                i_EndMinutes: tmd.i_EndTime > 0 ? tmd.i_EndTime.toString().slice(-2) : null
              }
            })
            this.a_ExamTypeID = response.ExamTypes
            if (this.a_ExamTypeID.length < 1) this.o_NewTermMarkDate.i_ExamTypeID = 1
            this.a_Header.splice(2, 0, ...this.a_TermMarkDate)
            this.getTermMarks()
            this.b_EditMarks = true && (this.a_TermMarkDate.length > 0)
            this.b_NewMark = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTermMarks () {
        if (this.i_CurrentClassSubjectID === null) return
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark`, {
          i_ClassID: this.o_CurrentClass.i_ClassID,
          i_AddressRoleID: this.i_TeacherAddressRoleID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ClassSubjectID: this.i_CurrentClassSubjectID,
        })
          .then(response => {
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_ClassStudent = response
            this.getQuarterMarks(this.i_CurrentClassSubjectID)
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTeacherStudies () {
        if (this.b_IsTeacherAdmin) {
          get(`${process.env.VUE_APP_SMT_API_URL}/data/period/studies`, {
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 1,
            i_SeminarCenterID: this.i_CurrentSeminarCenterID
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }
        else {
          get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/studies`, {
            i_AddressRoleID: this.i_TeacherAddressRoleID,
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 1,
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }
      },
      setTermMarks () {
        if (this.$v.$invalid) return
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark`, {
          a_Student: this.a_Student,
          i_AddressRoleID: this.i_TeacherAddressRoleID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ClassID: this.o_CurrentClass.i_ClassID,
        })
          .then(response => {
            this.editMode = false;
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.editMode = false
            this.getTermMarks()
            this.requestSent = true
            this.responseMessage = 'Resultate gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateTermMark (Ao_Student, Ai_TermMarkDateID, event) {
        let Lo_NewTermMark;
        let Li_TermMarkIndex;
        this.validateResult(Ao_Student, Ai_TermMarkDateID, event);
        const La_CurrentTermMarkDate = this.a_TermMarkDate.filter(termMarkDate => {
          return termMarkDate.i_TermMarkDateID === Ai_TermMarkDateID
        });
        Li_TermMarkIndex = Ao_Student.a_TermMarks.findIndex(o_TermMark => {
          return o_TermMark.i_TermMarkDateID === Ai_TermMarkDateID
        })
        if(Li_TermMarkIndex === -1) {
          Lo_NewTermMark = {
            f_Factor: La_CurrentTermMarkDate[0].f_Factor,
            f_Value: parseFloat(event),
            i_TermMarkDateID: Ai_TermMarkDateID,
            i_TermMarkID: null,
            s_Comment: null,
          }
          Ao_Student.a_TermMarks.push(Lo_NewTermMark)
        } else {
          Ao_Student.a_TermMarks[Li_TermMarkIndex].f_Value = (event === '') ? null : parseFloat(event)
        }
      },
      getTermMarkForSlot (Ai_TermMarkDateID, Ao_Student) {
        const a_CurrentTermMark = Ao_Student.a_TermMarks.filter(o_TermMark => {
          return o_TermMark.i_TermMarkDateID === Ai_TermMarkDateID
        });
        if (0 in a_CurrentTermMark) {
          //if (a_CurrentTermMark[0].f_Value)
          if (a_CurrentTermMark[0].f_Value == null) return null
          const Lf_Mark = Number.parseFloat(a_CurrentTermMark[0].f_Value)
          if (Lf_Mark === 'NaN') return null
          return Lf_Mark.toPrecision(2)
        }
        else return null
      },
      clearDataTables () {
        this.a_ClassStudent = []; 
        this.a_TermMarkDate = [];
        this.a_QuarterMark = [];
        this.a_Header = [...this.a_ConfigHeader];
        this.b_EditMarks = false
        this.b_NewMark = false
      },
      validateResult (Ao_Student, Ai_TermMarkDateID, Af_TermMark) {
        const Li_IndexError = this.a_TermMarkError.findIndex(error => {
          return error.i_AddressRoleID ===  Ao_Student.i_AddressRoleID &&
            error.i_TermMarkDateID === Ai_TermMarkDateID
        });
        this.f_CurrentResult = Af_TermMark;
        if(this.f_CurrentResult == null || this.f_CurrentResult == '') return;
        this.$v.f_CurrentResult.$touch();
        if(!this.$v.$invalid && Li_IndexError === -1) return;
        if(!this.$v.$invalid && Li_IndexError > -1) this.a_TermMarkError.splice(Li_IndexError, 1);
        if(this.$v.$invalid && Li_IndexError === -1) this.a_TermMarkError.push({
          i_AddressRoleID: Ao_Student.i_AddressRoleID,
          i_TermMarkDateID: Ai_TermMarkDateID,
          f_TermMark: Af_TermMark
        });
      },
      validateTermMarkDate() {
        this.b_EditTermMarkDate = true
        this.$v.o_NewTermMarkDate.$touch()
        this.$v.$touch()
        this.a_InvalidFields = Object.keys(this.$v.$params).filter(fieldName => this.$v[fieldName].$invalid)
        if (this.a_InvalidFields.length > 0) {
          return false
        }
        return true
      },
      cancelSaveTermMarks () {
        this.editMode=false; 
        this.f_CurrentResult=null; 
        this.a_TermMarkError=[]; 
        this.$v.$reset()
      },
      cancelEditTermMarkDate () {
        this.dialog = false
        this.b_EditTermMarkDate = false 
        this.o_NewTermMarkDate = {
          i_ExamTypeID: null,
          s_Name: null,
          s_Factor: null,
          i_TermMarkDateID: 0,
          i_EndHour: null,
          i_StartHour: null,
          i_StartMinutes: null,
          i_EndMinutes: null,
          i_LessonID: 0,
          b_Time: false
        },
        this.$v.$reset()
      },
      getErrorMessages (Ai_AddressRoleID, Ai_TermMarkDateID) {
        const errors = []
        const Li_IndexError = this.a_TermMarkError.findIndex(error => {
          return error.i_AddressRoleID ===  Ai_AddressRoleID &&
            error.i_TermMarkDateID === Ai_TermMarkDateID
        });
        if(Li_IndexError !== -1) errors.push('');
        return errors;
      },
      getQuarterMarks (Ai_ClassSubjectID) {
        this.a_QuarterMark = [];
        this.a_ClassStudent = this.a_ClassStudent.map(o_Student => ({
          ...o_Student, 
          quarter: null,
          s_SnipText: null
        }));
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/quarter-mark`, {
          i_ClassSubjectID: Ai_ClassSubjectID,
          i_PlanningPeriodID: this.getPlanningPeriodID
        })
          .then(response => {
            this.b_LoadingStudent = false
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_QuarterMark = response.map(o_QuarterMark => ({
              ...o_QuarterMark, 
              quarter: o_QuarterMark.b_ExistsMark ? o_QuarterMark.quarter : null,
              s_SnipText: o_QuarterMark.b_ExistsSnip ? o_QuarterMark.s_SnipText : null
            }));
            this.a_ClassStudent = this.a_ClassStudent.map(o_Student => ({
              ...o_Student, ...this.a_QuarterMark.find(o_QuarterMark => o_QuarterMark.i_AddressRoleID === o_Student.i_AddressRoleID)
            }));
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setSeminarCenterID (val) {
        this.clearDataTables()
        this.i_CurrentSeminarCenterID = val
      },
      isOwner (Ai_TermMarkDateID) {
        let La_CurrentTermMarkDate
        La_CurrentTermMarkDate = this.a_TermMarkDate.filter(o_TermMarkDate => {
          return o_TermMarkDate.i_TermMarkDateID === Ai_TermMarkDateID
        })
        if (0 in La_CurrentTermMarkDate) {
          return La_CurrentTermMarkDate[0].b_Owner
        }
        else return false
      },
      editTermMarkDate (e, Ao_TermMarkDate) {
        e.stopPropagation()
        this.o_NewTermMarkDate.i_TermMarkDateID = Ao_TermMarkDate.i_TermMarkDateID
        this.o_NewTermMarkDate.s_Name = Ao_TermMarkDate.s_Name
        this.o_NewTermMarkDate.s_Factor = String(Ao_TermMarkDate.f_Factor)
        this.o_NewTermMarkDate.i_ExamTypeID = Ao_TermMarkDate.i_ExamTypeID
        this.o_NewTermMarkDate.b_Time = Ao_TermMarkDate.i_LessonID ? true : false
        this.o_NewTermMarkDate.i_StartMinutes = Ao_TermMarkDate.i_StartMinutes
        this.o_NewTermMarkDate.i_EndMinutes = Ao_TermMarkDate.i_EndMinutes
        this.o_NewTermMarkDate.i_StartHour = Ao_TermMarkDate.i_StartHour
        this.o_NewTermMarkDate.i_EndHour = Ao_TermMarkDate.i_EndHour
        this.o_NewTermMarkDate.i_LessonID = Ao_TermMarkDate.i_LessonID
        this.s_ClassDate =  (new Date(new Date(Ao_TermMarkDate.t_Date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        this.dialog = true
        this.b_EditTermMarkDate = true
      },
    },
    beforeMount () {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TeacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TeacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      this.a_Header = [...this.a_ConfigHeader]
      this.a_SeminarCenter = this.getSeminarCenter
      this.b_IsTeacherAdmin = this.a_SeminarCenter.length > 0 && !this.getImpersonation.b_Status
      if (!this.b_IsTeacherAdmin) this.getTeacherStudies()
    },
    validations: {
      f_CurrentResult: {
        decimal,
        between: between(0, 100),
      },
      o_NewTermMarkDate: {
        i_ExamTypeID: { required: requiredIf(function() {
          return this.b_EditTermMarkDate
        })},
        s_Name: { required: requiredIf(function() {
          return this.b_EditTermMarkDate
        })},
        s_Factor: { required: requiredIf(function() {
          return this.b_EditTermMarkDate
        })},
        i_StartHour: {
          required: requiredIf(function() {
            return this.o_NewTermMarkDate.b_Time
          }),
          between: between(6, 23)
        },
        i_EndHour: {
          required: requiredIf(function() {
            return this.o_NewTermMarkDate.b_Time
          }),
          between: between(6, 23)
        },
        i_StartMinutes: {
          required: requiredIf(function() {
            return this.o_NewTermMarkDate.b_Time
          }),
          between0and55,
          fiveStep
        },
        i_EndMinutes: {
          required: requiredIf(function() {
            return this.o_NewTermMarkDate.b_Time
          }),
          between0and55,
          fiveStep
        },
      },
      t_StartDateTMD: {
        required: requiredIf(function() {
          return this.o_NewTermMarkDate.b_Time
        })
      },
      t_EndDateTMD: {
        required: requiredIf(function() {
          return this.o_NewTermMarkDate.b_Time
        }),
        minValue(val, { t_StartDateTMD }) {
          if (this.o_NewTermMarkDate.b_Time) return val > t_StartDateTMD
          return true
        },
      },
    },
  }
</script>
